export const getUserNameInitials = (name) => {
  const names = name.split(" ");
  if (names.length === 1) {
    return names[0].charAt(0).toUpperCase();
  }
  const firstName = names[0];
  const lastName = names[names.length - 1];
  const initials = firstName.charAt(0) + lastName.charAt(0);
  return initials.toUpperCase();
};

export const totalPages = (module) => {
  return this.$store.getters[`${module}/totalPages`];
}

const commonAttributesOne = [
  {
    text: "START DATE",
    value: "startDate",
    sortable: false,
    align: "center",
  },
  {
    text: "END DATE",
    value: "endDate",
    sortable: false,
    align: "center",
  },
  { text: "STATUS", value: "status", sortable: false, align: "center" },
  { text: "ACTION", value: "actions", sortable: false, align: "center" },
];

let commonHeaders = [
  { text: "NO.", align: "start", sortable: false, value: "no" },
  {
    text: "EMPLOYEE NAME",
    value: "displayName",
    sortable: false,
    align: "center",
  },
  {
    text: "EMPLOYEE ID",
    value: "employeeId",
    sortable: false,
    align: "center",
  },
];

let employeeCommonHeaders = [
  { text: "NO.", align: "start", sortable: false, value: "no" },
  {
    text: "EMPLOYEE NAME",
    value: "displayName",
  },
  {
    text: "EMPLOYEE ID",
    value: "employeeId",
  },
];
const employeeHeaders = [
  { text: "DEPARTMENT", value: "department" },
  { text: "JOB TITLE", value: "jobTitle" },
  {
    text: "SSNIT CONTRIBUTOR",
    value: "ssnitContributor",
    align: "center",
  },
  { text: "UNIQUE CODE", value: "payrollId", align: "center" },
  { text: "BASIC SALARY", value: "salary", align: "center" },
];

const bonusAttributes = [
  {
    text: "BONUS NAME",
    value: "description",
    sortable: false,
    align: "center",
  },
  {
    text: "TOTAL AMOUNT",
    value: "totalBonus",
    sortable: false,
    align: "center",
  },
  {
    text: "MONTHLY ADDITION",
    value: "monthlyBonus",
    sortable: false,
    align: "center",
  },
];
const bonusesAttributes = [
  { text: "BONUS (GH₵)", value: "bonusTotal" },
  { text: "TAX (GH₵)", value: "totalTaxOnBonus" },
  { text: "NET BONUS (GH₵)", value: "totalNetBonus" },
];

const allowanceAttributes = [
  {
    text: "ALLOWANCE NAME",
    value: "description",
    sortable: false,
    align: "center",
  },
  {
    text: "TAXABLE",
    value: "taxable",
    sortable: false,
    align: "center",
  },
  {
    text: "TOTAL AMOUNT",
    value: "totalAllowance",
    sortable: false,
    align: "center",
  },
  {
    text: "MONTHLY ALLOWANCE",
    value: "monthlyAllowance",
    sortable: false,
  },
];

const payrollAttributes = [
  { text: "NO.", value: "no", align: "start", sortable: false },
  { text: "EMPLOYEE NAME", value: "fullName" },
  { text: "EMPLOYEE ID", value: "employeeId" },
  { text: "JOB TITLE", value: "employeePosition" },
  { text: "BASIC SALARY (GH₵)", value: "basicSalary" },
  { text: "TOTAL ALLOWANCE (GH₵)", value: "totalAllowance" },
  { text: "TOTAL BONUS (GH₵)", value: "initialBonus" },
  { text: "ACTUAL BONUS (GH₵)", value: "bonusTotal" },
  { text: "EXCESS BONUS (GH₵)", value: "excessBonus" },
  { text: "BACK PAY (GH₵)", value: "backPay" },
];

const teamPAttributes = [
  { text: "JOB TITLE", value: "jobTitle" },
  { text: "SIX MONTHS SALARY (GH₵)", value: "sixMonthsSalary" },
  { text: "TEAM PERFORMANCE (GH₵)", value: "teamPerformance" },
  { text: "EXCESS TEAM PERFORMANCE (GH₵)", value: "excessTp" },
  { text: "BONUS TAX (GH₵ )", value: "bonus" },
];

const deductionAttributes = [
  { text: "DEDUCTION NAME", value: "description", align: "center" },
  { text: "TOTAL DEDUCTION", value: "amountDue", align: "center" },
  {
    text: "MONTHLY DEDUCTION",
    value: "monthlyDeduction",
    sortable: false,
  },
];

const payrollRepAttributes = [
  { text: "JOB TITLE", value: "employeePosition" },
  { text: "BASIC SALARY (GH₵)", value: "basicSalary" },
  { text: "TOTAL ALLOWANCE (GH₵)", value: "totalAllowance" },
  { text: "TOTAL BONUS (GH₵)", value: "initialBonus" },
  { text: "ACTUAL BONUS (GH₵)", value: "bonusTotal" },
  { text: "EXCESS BONUS (GH₵)", value: "excessBonus" },
  { text: "BACK PAY (GH₵)", value: "backPay" },
  { text: "GROSS SALARY (GH₵)", value: "initialGross" },
  { text: "EMPLOYEE SSF (GH₵)", value: "employeeSSF" },
  { text: "EMPLOYEE TIER 3 (GH₵)", value: "employeeTierThreePre" },
  { text: "TAX RELIEF (GH₵)", value: "taxRelief" },
  { text: "TOTAL RELIEF (GH₵)", value: "totalRelief" },
  { text: "TAXABLE INCOME (GH₵)", value: "taxableIncome" },
  { text: "BONUS TAX (GH₵)", value: "totalTaxOnBonus" },
  { text: "PAYE (GH₵)", value: "paye" },
  { text: "STUDENT LOAN (GH₵)", value: "studentLoanTrustFund" },
  { text: "LOAN DEDUCTION (GH₵)", value: "loanDeduction" },
  { text: "TOTAL DEDUCTION (GH₵)", value: "totalDeduction" },
  { text: "NET SALARY (GH₵)", value: "netSalary" },
  { text: "EMPLOYER SSF (GH₵)", value: "employerSSF" },
  { text: "EMPLOYER TIER 3 (GH₵)", value: "employerTierThreePre" },
  {
    text: "EMPLOYEE COST TO COMPANY (GH₵)",
    value: "employeeCostToCompany",
  },
];

const graFirstCommonHeaders = [
  { text: "Ser. No", align: "start", sortable: false, value: "no" },
  { text: "TIN (11 characters)/Ghana Card", value: "ghanaCard" },
  { text: "Name Of Employee", value: "fullName" },
  { text: "Position", value: "employeePosition" },
  { text: "Non-Resident (Y / N)", value: "nonResident" },
  { text: "Basic Salary", value: "basicSalary" },
  { text: "Secondary Employment (Y / N)", value: "secondaryEmployment" },
  { text: "Social Security Fund", value: "employeeSSF" },
  { text: "Third Tier", value: "employeeTierThreePre" },
  { text: "Cash Allowances", value: "totalAllowance" },
  {
    text: "Bonus Income(up to 15% of Annual Basic salary)",
    value: "initialBonus",
  },
  { text: "Final Tax on Bonus Income", value: "totalTaxOnBonus" },
  { text: "Excess Bonus", value: "excessBonus" },
];

const graSecondCommonHeaders = [
  { text: "Accommodation Element", value: "accommodationElement" },
  { text: "Vehicle element", value: "vehicleElement" },
  { text: "Non Cash Benefit", value: "nonCashBenefit" },
  { text: "Assessable Income", value: "assessableIncome" },
  { text: "Deductible Reliefs", value: "taxRelief" },
  { text: "Total Reliefs", value: "totalRelief" },
  { text: "Chargeable Income", value: "chargeableIncome" },
  { text: "Tax Deductible", value: "paye" },
  { text: "Overtime Income", value: "overtimeIncome" },
  { text: "Overtime Tax", value: "overtimeTax" },
  { text: "Total Tax Payable to GRA", value: "totalTaxPayableToGRA" },
];
const graThirdCommonHeaders = [{ text: "Remarks", value: "remarks" }];
const graReportEmolument = [
  { text: "Total Cash emolument (6+10+13)", value: "cashEmolument" },
];
const graReportSeverance = [
  { text: "Severance pay paid", value: "severancePay" },
];
const graPayrollEmolument = [
  { text: "Total Cash emolument", value: "cashEmolument" },
];
const graPayrollSeverance = [
  { text: "Severance pay paid", value: "severancePayPaid" },
];
const taxAndSsnitHeaders = [
  {
    text: "LABEL",
    sortable: false,
    value: "label",
  },
];
const ssnitHeader = [
  { text: "RATE(%)", value: "percentage", sortable: false },
  { text: "ACTIONS", value: "actions", align: "end", sortable: false },
];
const taxHeader = [
  { text: "CHARGEABLE INCOME", value: "amount", sortable: false },
  { text: "RATE(%)", value: "percentage", sortable: false },
  { text: "ACTIONS", value: "actions", align: "end", sortable: false },
];
const bankAdviceHeaders = [
  {
    text: "NO.",
    align: "start",
    sortable: false,
    value: "no",
  },
  { text: "PAYORBANKROUTNO", value: "bankName" },
  { text: "TRUNCATINGRTNO", value: "sortCode" },
  { text: "ACCOUNTNO", value: "accountNumber" },
  { text: "DEPOSITORACCT", value: "depositorAccountNumber" },
  { text: "PAYERNAME", value: "payerName" },
  { text: "PAYEENAME", value: "accountName" },
  { text: "INSTRUMENTTYPE", value: "instrumentType", align: "center" },
  { text: "AMOUNT", value: "netSalary", align: "center" },
];
let payrollBankAdvice = [
  { text: "PRESENTMENTDATE", value: "paymentDate" },
  { text: "TRANSDETAIL", value: "transDetail" },
];
let reportBankAdvice = [
  { text: "PRESENTMENTTYPE", value: "paymentDate" },
  { text: "TRANSDETAIL", value: "transDetail" },
];
const commonTierHeaders = [
  {
    text: "NO.",
    align: "start",
    sortable: false,
    value: "no",
  },
  { text: "EMPLOYEE NAME", value: "fullName" },
  { text: "SSNIT NUMBER", value: "customSSN" },
  { text: "BASIC SALARY", value: "basicSalary" },
];
const tierOneHeader = [{ text: "SSF (13.5%)", value: "tierOne" }];
const tierTwoHeader = [{ text: "SSF (5%)", value: "tierTwo" }];
const modifyHeader = (header, sortable, align) => ({
  ...header,
  sortable,
  align,
});

const handleDisplayName = (header) => {
  if (header.value === "displayName") {
    return modifyHeader(header, false, "center");
  }
  return header;
};

const handleEmployeeId = (header) => {
  if (header.value === "employeeId") {
    return modifyHeader(header, false, "center");
  }
  return header;
};

const addHeaderProperty = (arr) => {
  return arr.map((header) => {
    return handleDisplayName(handleEmployeeId(header));
  });
};
const addBonus = (headers) => [
  ...headers,
  ...bonusAttributes,
  ...commonAttributesOne,
];
const addAllowance = (headers) => [
  ...headers,
  ...allowanceAttributes,
  ...commonAttributesOne,
];
const addPayroll = () => [...payrollAttributes];
const addTeamPerformance = (headers) => [...headers, ...teamPAttributes];
const addDeduction = (headers) => [
  ...headers,
  ...deductionAttributes,
  ...commonAttributesOne,
];
const addPayrollReport = (headers) => [...headers, ...payrollRepAttributes];
const addGraPayroll = () => [
  ...graFirstCommonHeaders,
  ...graPayrollEmolument,
  ...graSecondCommonHeaders,
  ...graPayrollSeverance,
  ...graThirdCommonHeaders,
];
const addGraReport = () => [
  ...graFirstCommonHeaders,
  ...graReportEmolument,
  ...graSecondCommonHeaders,
  ...graReportSeverance,
  ...graThirdCommonHeaders,
];
const addTax = () => [...taxAndSsnitHeaders, ...taxHeader];
const addSsnit = () => [...taxAndSsnitHeaders, ...ssnitHeader];
const addPayrollBankAdvice = () => [...bankAdviceHeaders, ...payrollBankAdvice];
const addReportBankAdvice = () => [...bankAdviceHeaders, ...reportBankAdvice];
const addBonuses = (headers) => [...headers, ...bonusesAttributes];
const addTierOne = () => [...commonTierHeaders, ...tierOneHeader];
const addTierTwo = () => [...commonTierHeaders, ...tierTwoHeader];
const addEmployee = (headers) => [...headers, ...employeeHeaders];

export const createHeaders = (requiredAttributes) => {
  let newHeaders;
  let newAllowanceHeaders;
  switch (requiredAttributes) {
    case "bonus":
      newHeaders = addHeaderProperty(employeeCommonHeaders);
      return addBonus(newHeaders);
    case "allowance":
      newAllowanceHeaders = addHeaderProperty(employeeCommonHeaders);
      return addAllowance(newAllowanceHeaders);
    case "payroll":
      return addPayroll();
    case "teamPerformance":
      return addTeamPerformance(employeeCommonHeaders);
    case "deduction":
      return addDeduction(commonHeaders);
    case "payrollReport":
      return addPayrollReport(employeeCommonHeaders);
    case "graPayroll":
      return addGraPayroll();
    case "graReport":
      return addGraReport();
    case "tax":
      return addTax();
    case "ssnit":
      return addSsnit();
    case "payrollBankAdvice":
      return addPayrollBankAdvice();
    case "reportBankAdvice":
      return addReportBankAdvice();
    case "bonuses":
      return addBonuses(employeeCommonHeaders);
    case "tierOne":
      return addTierOne();
    case "tierTwo":
      return addTierTwo();
    case "employee":
      return addEmployee(employeeCommonHeaders);
    default:
      return [];
  }
};
