<template v-slot:activator="{ on }">
  <v-dialog
    v-model="openBulk"
    max-width="500px"
    :retain-focus="false"
    overlay-color="black"
    overlay-opacity="0.1"
    content-class="elevation-2"
    persistent
  >
    <v-card height="300px">
      <v-card style="background-color: #f5f5f5">
        <v-card-title>
          Team Lead Access Level
          <v-btn
            @click="closeDialog"
            class="text-gray-400 bg-transparent rounded-lg ml-auto"
            depressed
          >
            <img src="../../assets/close-circle.svg" alt="close-circle-icon" />
          </v-btn>
        </v-card-title>
        <v-row class="ml-5">
          <v-col
            cols="12"
            sm="12"
            md="3"
            class="d-flex justify-content-between w-50 rounded-lg"
          >
            <v-text-field
              @keyup.enter="searchEmployees"
              solo
              prepend-inner-icon="mdi-magnify"
              label="Search By Name"
              dense
              clearable
              @click:clear="resetData"
              flat
              color="primary"
              background-color="white lighten-4"
              v-model="query"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-text>
          <v-list v-for="employee in updatedEmployees" :key="employee.userId">
            <v-list-item class="d-flex flex-row">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center text-gray-400">
                  <div
                    class="avatar d-flex align-center justify-center text-center rounded-full mr-2 pt-4"
                  >
                    <ProfileImageComponent
                      widthSize="2.5rem"
                      heightSize="2.5rem"
                      text-size="small"
                      :initials="getUserNameInitials(getName(employee))"
                    />
                  </div>

                  {{ getName(employee) }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  @click.prevent="removeAccess(employee.userId)"
                  color="white"
                  class="red--text"
                  elevation="0.1"
                  >Remove</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { getUserNameInitials } from "../../services/functions";
import ProfileImageComponent from "../payrolls/ProfileImageComponent.vue";
export default {
  components: {
    ProfileImageComponent,
  },
  props: {
    openBulk: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    selectedEmployees: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      filteredItem: [],
      query: "",
    };
  },
  computed: {
    updatedEmployees() {
      return this.query === "" ? this.selectedEmployees : this.filteredItem;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-bulk-dialog");
    },
    getUserNameInitials,
    removeAccess(id) {
      this.$emit("remove-access", id);
    },
    getName(item) {
      if (Array.isArray(item)) {
        return item
          .map((person) => `${person.firstName} ${person.lastName}`)
          .join(", ");
      } else {
        return `${item.firstName} ${item.lastName}`;
      }
    },
    searchEmployees() {
      if (this.query === "") {
        this.filteredItem = this.selectedEmployees;
      } else {
        this.filteredItem = this.selectedEmployees.filter((item) => {
          return this.getName(item)
            .toLowerCase()
            .includes(this.query.toLowerCase());
        });
      }
      return this.filteredItem;
    },
    resetData() {
      this.query = "";
      this.filteredItem = this.selectedEmployees;
    }
  },
};
</script>

<style scoped>
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #e6e8f0;
  cursor: pointer;
  transition: transform 0.3s ease;
}
</style>
