<template v-slot:activator="{ on }">
  <v-dialog v-model="dialog" max-width="600px" :retain-focus="false" persistent>
    <v-card>
      <v-card-title>Edit Access Level</v-card-title>
      <v-card-text>
        <v-text-field v-model="name" label="Name"></v-text-field>
        <v-text-field v-model="description" label="Description"></v-text-field>
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            Choose Permissions
          </div>
          <div class="d-flex p-1 align-center">
            <span style="line-height: 0.4rem"
              ><toggle-component
                :model-value="toggle"
                @updateModel="updateToggle($event)"
              ></toggle-component
            ></span>
            <p class="ml-3">Enable All</p>
          </div>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card
            v-for="(card, index) in cardData"
            :key="card.name"
            class="d-flex justify-space-between items-center mb-5"
          >
            <div>
              <v-card-title>{{ card.name }}</v-card-title>
              <v-card-text>{{ card.content }}</v-card-text>
            </div>
            <span>
              <flex-col-toggle
                :model-value="card.permit"
                @updateValue="updateValue($event, index)"
                :key="index"
              >
              </flex-col-toggle>
            </span>
          </v-card>
        </div>
        <div>
          <v-autocomplete
            style="width: 500px"
            v-model="selectedItems"
            item-value="id"
            item-text="displayName"
            :items="assignItem"
            label="Select Employees"
            multiple
          ></v-autocomplete>
        </div>
        <!-- Other input fields based on the mutation -->
      </v-card-text>
      <v-card-actions class="d-flex align-center justify-space-between">
        <v-btn @click="closeDialog" color="secondary">Close</v-btn>
        <div>
          <v-btn
            @click="saveChanges"
            v-model="saveForm"
            color="primary"
            >Save</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ToggleComponent from "../AccessLevel/ToggleComponent.vue";
import FlexColToggle from "../AccessLevel/FlexColToggle.vue";
import { cardData } from "../../services/serviceData";

export default {
  components: {
    ToggleComponent,
    FlexColToggle,
  },

  data() {
    return {
      name: "",
      id: "",
      permissions: [],
      description: "",
      index: 0,
      toggle: false,
      saveForm: false,
      dialog: false,
      selectedItems: [],
      cardData: [...cardData],
    };
  },
  computed: {
    listPermissions() {
      return this.$store.state.access.permissions;
    },
    getFormData() {
      return this.$store.state.access.updateForm;
    },
    listAccess() {
      return this.$store.state.employees.employeeIdsNames;
    },
    getAccessLevelList() {
      return this.$store.state.access.accessLevels;
    },
    assignItem() {
      return this.listAccess.map((access) => ({
        displayName: access.displayName,
        id: access.userId,
      }));
    },
  },
  created() {
    this.$store.dispatch("access/listPermissions");
    this.$store.dispatch(
            "employees/fetchEmployeesIdsNames",
        );
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    updateToggle(value) {
      this.cardData.forEach((card) => {
        card.permit = value;
        this.toggle = value;
        if (this.toggle) {
          this.$store.dispatch(
            "access/updatePermissions",
            this.listPermissions
          );
        } else {
          this.$store.dispatch("access/updatePermissions", []);
        }
      });
    },

    updateValue(value, index) {
      this.index = index;
      try {
        if (index === undefined || index === null) {
          return;
        }

        const card = this.cardData[index];
        if (card.permit === value) {
          return;
        }

        card.permit = value;
        this.saveForm = value ?? false;

        const switchedOnIndexes = this.cardData.reduce(
          (acc, el, i) => (el.permit ? [...acc, i] : acc),
          []
        );

        const allSwitchesToggled =
          switchedOnIndexes.length === this.cardData.length;
        this.toggle = allSwitchesToggled;

        const managerPerm = this.calculateManagerPermissions(
          switchedOnIndexes,
        );
        console.log(managerPerm);
        if (managerPerm.length > 0) {
          this.$store.dispatch("access/updatePermissions", managerPerm);
        }
      } catch (err) {
        console.log(err);
      }
    },

    calculateManagerPermissions(switchedOnIndexes) {
      if (switchedOnIndexes.length === 0) {
        return [];
      } else if (switchedOnIndexes.length === 1) {
        return [this.cardData[switchedOnIndexes[0]].permission];
      } else {
        return switchedOnIndexes.map((i) => this.cardData[i].permission);
      }
    },

    updateForm() {
      if (this.name !== "" && this.description !== "") {
        const data = {
          name: this.name,
          description: this.description,
          userIds: this.selectedItems,
        };
        this.$store.commit("access/UPDATE_FORM_DATA", data);
      }
    },

    async saveChanges() {
      this.updateForm();
      const updateData = {
        id: this.id,
        accessLevelInput: this.getFormData,
      };
      this.closeDialog();
      await this.$store.dispatch("access/updateAccessLevel", updateData);
      this.permissions = [];
      this.selectedItems = [];
    },
  },
};
</script>
