<template v-slot:activator="{ on }">
  <v-dialog
    v-model="openDialog"
    max-width="500px"
    :retain-focus="false"
    overlay-color="black"
    overlay-opacity="0.1"
    content-class="elevation-2"
    persistent
  >
    <v-card>
      <v-card-title>
        Assignee
        <v-btn
          @click="closeDialog()"
          class="text-gray-400 bg-transparent rounded-lg ml-auto"
          depressed
        >
          <img src="../../assets/close-circle.svg" alt="close-circle-icon" />
        </v-btn>
      </v-card-title>
      <v-card-text class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <div
            class="avatar d-flex align-center justify-center text-center rounded-full mr-2 pt-4"
          >
            <ProfileImageComponent
              widthSize="2.5rem"
              heightSize="2.5rem"
              text-size="small"
              :initials="getUserNameInitials(firstName + lastName)"
            />
          </div>
          <div>
            <p>{{ firstName }} {{ lastName }}</p>
            <p>{{ jobTitle }}</p>
          </div>
        </div>
        <v-card-actions>
          <v-btn
            color="white"
            class="red--text"
            elevation="0.1"
            @click="revokeAccess()"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfileImageComponent from "../payrolls/ProfileImageComponent.vue";
import { getUserNameInitials } from "../../services/functions";

export default {
  components: {
    ProfileImageComponent,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    jobTitle: {
      type: String,
      default: "",
    },
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    getUserNameInitials,
    closeDialog() {
      this.$emit("close-dialog");
    },
    revokeAccess() {
      this.$emit("revoke-access");
    },
  },
};
</script>
<style scoped>
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #e6e8f0;
  cursor: pointer;
  transition: transform 0.3s ease;
}
</style>
