<template>
  <div class="access-container">
    <template>
      <v-snackbar :timeout="5000" :value="snackBar" top :color="isError ? 'error' : 'success'" center v-model="snackBar"
        outlined text elevation="1">
        {{ snackBarText }}
      </v-snackbar>
    </template>
    <div v-if="isLoading">
      <spinner></spinner>
    </div>
    <div class="access-header">
      <h3 class="H700 N900">
        Access level ({{ getAccessLevelList?.length || 0 }})
      </h3>
      <div>
        <button-component @submit="createAccess()">Create Access Level</button-component>
      </div>
    </div>
    <p class="block N800 P200 pl-2">
      This table contains a list of all access levels for Payroll.
    </p>
    <main>
      <div class="main-box1">
        <table aria-label="department table" class="tab-box">
          <thead class="bg-blue lighten-5 border border-bottom-1 grey--text text--darken-3"
            style="border-color: #e6e8f0; color: #101840">
            <tr>
              <th scope="col" class="py-4 px-4 normal-case"
                style="font-weight: 700; font-size: 0.833rem; line-height: 16px">
                Access Level
              </th>
              <th scope="col" class="py-4 px-4 normal-case"
                style="font-weight: 700; font-size: 0.833rem; line-height: 16px">
                People
              </th>
              <th scope="col" class="py-4 px-4 normal-case"
                style="font-weight: 700; font-size: 0.833rem; line-height: 16px">
                Action
              </th>
            </tr>
          </thead>

          <tbody v-if="getAccessLevelList.length > 0">
            <tr class="access-list" v-for="item in sortedAccessLevels" :key="item.id" :items="item" item-value="id">
              <td class="auto px-4 cursor-pointer">
                <span class="flex items-center">
                  <span class="description" v-if="item.name">{{
        item.name
      }}</span>
                </span>
              </td>

              <td class="pa-4 space-x-4 d-flex align-center">
                <template>
                  <div class="avatar d-flex align-center text-center rounded-full ring-2 ring-gray lighten-300 pt-4"
                    v-for="avatar in item.employees.slice(0, 3)" :key="avatar.id"
                    @click.prevent="openAccessDialog(avatar)">
                    <AccessDialog :open-dialog="openDialog" @close-dialog="closeDialog" :first-name="firstName"
                      :last-name="lastName" :job-title="jobTitle" @revoke-access="revokeAccess(id)" :key="avatar.id"
                      :item="avatar" :id="id">
                    </AccessDialog>
                    <ProfileImageComponent widthSize="2.5rem" heightSize="2.5rem" text-size="small" :initials="getUserNameInitials(
        avatar?.displayName
      )
        " />
                  </div>
                </template>
                <MultipleAccessDialog :open-bulk="openBulk" @close-bulk-dialog="closeBulkDialog"
                  @remove-access="removeAccess" :items="getEmployeeList" :selected-employees="selectedAccessLevel">
                </MultipleAccessDialog>
                <div v-if="item.employees.length > 3" class="py-1 px-4 hidden-avatar avatar cursor-pointer"
                  @click.prevent="openBulkDialog(item)">
                  +{{ item.employees?.length - 3 }}
                </div>
              </td>
              <v-dialog v-model="dialogDelete" overlay-color="black" overlay-opacity="0.1" :retain-focus="false"
                max-width="500px" content-class="elevation-2">
                <v-card>
                  <v-card-text class="text-h6">Are you sure you want to delete this item?</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary darken-1" @click="closeDelete" data-cy="cancelBtn">Cancel</v-btn>
                    <v-btn color="primary darken-1" @click.prevent="handleDelete()" data-cy="deleteBtn">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <td class="py-1 px-4" style="text-align: right">
                <div class="dropdown">
                  <img src="../../assets/actions-icon.svg" class="drop-btn" alt="Actions" />
                  <div class="dropdown-content">
                    <p class="P100 N600 pt-3 pl-5 pr-3 text-left">ACTIONS</p>
                    <a href="javascript:void(0)" class="flex P200" @click.prevent="openEditDialog(item)">
                      <img src="../../assets/edit-icon.svg" class="SPC-MR-100" alt="Edit" />Edit</a>
                    <a href="javascript:void(0)" class="P200 flex content-center"
                      @click.prevent="handleDeleteConfirm(item.id)">
                      <img src="../../assets/trash.svg" class="SPC-MR-100" alt="Delete" />Delete</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <dialog-component ref="editDialog" :id="itemId"></dialog-component>
        <div class="text-center pt-2">
          <template> </template>
        </div>
      </div>
      <div v-if="getAccessLevelList?.length === 0" class="not-found">
        <img src="../../assets/not-found.svg" alt="Not found image" />
        <p class="grey--text text--lighten-1">
          You haven't created any access level for your organization yet :(
        </p>
      </div>
    </main>
    <v-pagination v-model="page" :length="totalPages" @input="paginatePage()" circle></v-pagination>
  </div>
</template>

<script>
import ButtonComponent from "../../components/payrolls/ButtonComponent.vue";
import ProfileImageComponent from "../../components/payrolls/ProfileImageComponent.vue";
import DialogComponent from "./DialogComponent.vue";
import { getUserNameInitials } from "../../services/functions";
import AccessDialog from "./AccessDialog.vue";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import MultipleAccessDialog from "./MultipleAccessDialog.vue";

export default {
  mixins: [generalPageMixin],
  components: {
    ButtonComponent,
    ProfileImageComponent,
    DialogComponent,
    AccessDialog,
    MultipleAccessDialog,
  },
  data: () => ({
    itemId: "",
    dialog: false,
    isLoading: false,
    dialogDelete: false,
    snackBar: false,
    snackBarText: "",
    isError: false,
    openDialog: false,
    openBulk: false,
    firstName: "",
    lastName: "",
    jobTitle: "",
    id: "",
    deletedId: "",
    selectedAccessLevel: [],
    storePath: 'access',
  }),
  computed: {
    getAccessLevelList() {
      return this.$store.state.access.accessLevels;
    },
    sortedAccessLevels() {
      return this.getAccessLevelList.slice().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    getEmployeeList() {
      return this.getAccessLevelList.flatMap((item) => item.employees);
    },

    getPermissions(id) {
      return this.getAccessLevelList.forEach((level) => {
        if (level.id === id) {
          this.id = level.id;
          return level.permissions;
        }
      });
    },
  },

  methods: {
    async createAccess() {
      if (this.$route.path !== "/create-level") {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/create-access");
        }, 1000);
      }
    },
    revokeAccess(id) {
      this.$store.dispatch("access/revokeAccessLevel", id);
      this.openDialog = false;
    },

    openBulkDialog(accessLevel) {
      this.selectedAccessLevel = accessLevel.employees;
      this.openBulk = true;
    },
    removeAccess(id) {
      this.$store.dispatch("access/revokeAccessLevel", id);
      this.openBulk = false;
    },
    getUserNameInitials,
    handleDelete() {
      this.$store.dispatch("access/deleteAccessLevel", this.deletedId);
      this.dialogDelete = false;
    },

    handleDeleteConfirm(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    openEditDialog(item) {
      if (this.$refs.editDialog) {
        this.$refs.editDialog.name = item.name || "";
        this.$refs.editDialog.description = item.description || "";
        this.$refs.editDialog.id = item.id;
        this.$refs.editDialog.selectedItems = item.employees.map(
          (el) => el.userId
        );
        this.$refs.editDialog.permissions = item.permissions.map(
          (permission) => permission.name
        );

        this.$refs.editDialog.cardData.forEach((card) => {
          card.permit = item.permissions.some(
            (permission) => permission.name === card.permission
          );
        });

        this.$refs.editDialog.dialog = true;
      }
    },
    openAccessDialog(avatar) {
      this.openDialog = true;
      this.firstName = avatar.firstName;
      this.lastName = avatar.lastName;
      this.jobTitle = avatar.jobTitle;
      this.id = avatar.userId;
    },

    closeDialog() {
      this.openDialog = false;
    },

    closeBulkDialog() {
      this.openBulk = false;
    },
    async paginatePage() {
      this.isLoading = true;
      this.scrollToTop();
      await this.$store.dispatch("access/listAccessLevel", {
        page: this.page || 1,
      });
      this.isLoading = false;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  created() {
    this.$store.dispatch("access/listAccessLevel", {
      page: this.page || 1,
    });
  },
  mounted() {
    const handleEvent = (message, isError = false) => {
      this.snackBar = true;
      this.snackBarText = message;
      if (isError) {
        this.isError = true;
      }
    };
    Fire.$on("update-success", (message) => handleEvent(message));
    Fire.$on("update-error", (message) => handleEvent(message, true));
    Fire.$on("create-success", (message) => handleEvent(message));
    Fire.$on("create-error", (message) => handleEvent(message, true));
    Fire.$on("delete-success", (message) => handleEvent(message));
    Fire.$on("delete-error", (message) => handleEvent(message, true));
    Fire.$on("revoke-success", (message) => handleEvent(message));
    Fire.$on("revoke-error", (message) => handleEvent(message, true));
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

li {
  list-style: none;
}

.access-container {
  background: #FAFBFF;
  border-radius: 8px;
  padding-top: 0.75rem;
}

.info-circle {
  margin-bottom: 10px;
  padding-top: 0.45rem;
  padding-right: 1rem;
}

.access-header {
  padding: 0 1rem 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
}

.main-box1 {
  overflow-x: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0.4rem;
}

.not-found {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

table {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

tbody {
  height: 100%;
  width: 100%;
}

table .tab-box {
  border: 1px solid #e6e8f0;
  border-radius: 60px;
  table-layout: auto;
  color: #718096;
  margin-bottom: 2.5rem;
  text-align: left;
}

table .access-list {
  border: 1px solid #e6e8f0;
  border-radius: 60px;
}

table .access-list tr:nth-child(even) {
  border: 1px solid #e6e8f0;
}

tbody tr:hover {
  background-color: #edeff5;
}

table th {
  background: #e6e8f0;
}

table th:nth-child(3) {
  text-align: right;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  background: #e6e8f0;
  margin-left: -13px;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
}

.avatar:hover:not(:last-of-type) {
  transform: translate(-10px);
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-group {
  display: flex;
  direction: rtl;
}

.hidden-avatar {
  width: 40px;
  height: 40px;
  background-color: rgb(82, 82, 82);
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 23px;
  margin-left: 3px;
}

.drop-btn {
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.16);
  border-radius: 8px;
  z-index: 4;
  right: 30px;
  bottom: -40px;
}

.dropdown-content.last {
  bottom: 0;
  left: 20px;
}

.dropdown-content a {
  color: #474d66;
  padding: 6px 6px;
  text-decoration: none;
  display: flex;
}

.dropdown-content a:hover {
  background-color: #f9e0d7;
  color: #dd5928;
  border-left: 3px solid #dd5928;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .drop-btn {
  background-color: #fafbff;
  border-radius: 8px;
}

.tooltip-text {
  max-width: 400px;
  max-height: 100px;
  background-color: hwb(0 0% 100% / 0.63);
  color: #fff;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  margin-top: 56px;
  display: none;
}

.description:hover~.tooltip-text {
  display: block;
}

@media screen and (max-width: 1023px) {
  .addEventButton:before {
    content: "+";
  }
}
</style>
