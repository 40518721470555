<template>
    <nav aria-label="Breadcrumb" :class="className" class="breadcrumb">
      <ol>
        <li
          v-for="(crumb, index) in breadcrumbs"
          :key="index"
          :class="{
            activePage: index === breadcrumbs.length - 1,
            notActive: index !== breadcrumbs.length - 1,
          }"
        >
          <div @click="toCrumb(crumb)" class="flex items-center gap-2"
            >{{ crumb.label }}
            <span
              class="mx-1 text-[#8F95B2]"
              v-if="index !== breadcrumbs.length - 1"
            >
              >
            </span>
          </div>
        </li>
      </ol>
    </nav>
  </template>
  
  <script>
  export default {
    props: {
      breadcrumbs: {
        type: Array,
        required: true,
      },
      className: {
        type: String,
      },
    },
    methods: {
      toCrumb(crumb) {
        return this.$router.push(crumb.to);
      }
    }
  };
  </script>
  
  <style scoped>
  nav {
    padding: 0 !important;
  }

  a{
    text-decoration: none;
  }

  ol {
    margin-block-start: 0;
    padding-inline-start: 0;
  }
  .breadcrumb ol {
    list-style: none;
  }
  
  .breadcrumb li {
    display: inline-block;
    cursor: pointer;
  }

  .breadcrumb li.activePage {
    color: #dd5928;
  }
  
  .breadcrumb li.notActive {
    color: #8f95b2;
  }
  </style>
  