<template>
  <div>
    <img
      v-show="showImage"
      :style="{ width: widthSize, height: heightSize, border: border }"
      class="rounded-full"
      data-cy="roundedFull"
      :src="imgSrc"
      :alt="name"
    />

    <div
      v-show="!showImage"
      :style="{ width: widthSize, height: heightSize }"
      class="relative inline-flex items-center justify-center overflow-hidden rounded-full bg-grey lighten-4 dark:bg-grey darken-3"
    >
      <span
        :class="textSize"
        class="font-medium grey--text bg-grey text--darken-3 uppercase"
        >{{ initials }}</span
      >
    </div>

    <div v-show="deactivated" class="deactivated">
      <img class="z-0 h-20" src="#" data-cy="videoIcon" alt="video-icon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
    },
    initials: {
      type: String,
      default: "N/A",
    },
    widthSize: {
      type: String,
      default: "3rem",
    },
    heightSize: {
      type: String,
      default: "3rem",
    },
    textSize: {
      type: String,
      default: "text-xl",
    },
    border: {
      type: String,
      default: "4px solid white",
    },
    deactivated: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "Profile Image",
    },
  },
  computed: {
    showImage() {
      return this.imgSrc && this.imgSrc !== "null";
    },
  },
};
</script>

<style scoped>
img {
  border-radius: 50%;
}

.deactivated {
  position: absolute;
  top: 10rem;
}
</style>
